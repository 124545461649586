import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from "styled-components"

import Layout from '../components/layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'
import { MDXRenderer } from "gatsby-plugin-mdx"

interface Props {
  data: {
    mdx: any
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
  location: any
  pageContext: any
}

const YearItemTemplate = ({ data, pageContext, location }: Props) => {
  const year = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const MarkdownBody = styled.p`
    color: ${year.frontmatter.textColor};
    
    a {
      color: ${year.frontmatter.textColor} !important;
    }
  `

  return (
    <Layout location={location} title={siteTitle} pageColor={ year.frontmatter.pageColor } pageContrastColor={ year.frontmatter.pageContrastColor } textColor={ year.frontmatter.textColor } logoColor={ year.frontmatter.logoColor}>
      <SEO
        title={year.frontmatter.title}
      />
      <h1
        style={{
          fontFamily: 'Crimson Text, serif',
          fontWeight: 400,
          fontSize: '1rem',
          fontStyle: 'italic',
          marginTop: rhythm(1),
          marginBottom: 0,
          color: year.frontmatter.textColor,
          textAlign: 'center'
        }}
      >
        {year.frontmatter.title}
      </h1>
      <p
        style={{
          ...scale(-1 / 5),
          display: `block`,
          marginBottom: rhythm(1),
          color: year.frontmatter.textColor
        }}
      >
      </p>
      <div style={{ display: 'flex'}}>
        <MarkdownBody>
          <p style={{ color: year.frontmatter.textColor}}>
            <MDXRenderer>{ year.body }</MDXRenderer>
          </p>
        </MarkdownBody>
      </div>
    </Layout>
  )
}

export default YearItemTemplate

export const pageQuery = graphql`
  query YearItemBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        pageColor
        pageContrastColor
        textColor
        logoColor
      }
    }
  }
`
